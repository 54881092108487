import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "vue";

import isEqual from "lodash-es/isEqual";
import intersection from "lodash-es/intersection";

import { SiteItemForFilter } from "@/api/interfaces/SitesInterfaces";

import {
  AZ_MESSAGE,
  CORRECT_PHONE_CODE_MESSAGE,
  CORRECT_PHONE_NUMBER_MESSAGE,
  EMAIL_CORRECT_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  LAST_NAME_MESSAGE,
  USER_WITH_SAME_EMAIL_MESSAGE,
} from "@/utils/validationMessages";
import { PHONE_CODE_REGEX, PHONE_NUMBER_REGEX } from "@/utils/validationRegex";
import { UserProfile } from "@/api/interfaces/UserInterfaces";
import {
  FilterItem,
  FilterObject,
  FilterOption,
} from "@/components/DropdownFilter/DropdownFilter";
import { getUserRoleTitle, UserRole, userRolesList } from "@/uses/useUserRoles";

import DropdownFilter from "@/components/DropdownFilter/DropdownFilter.vue";
import LeavePreventPopup from "@/components/LeavePreventPopup/LeavePreventPopup.vue";

import { EMAIL_REGEX, NAME_REGEX } from "@/utils/validationRegex";
import router from "@/router";

import { FIRST_NAME_MESSAGE } from "@/utils/validationMessages";
import { useStore } from "@/store/store";
import { createUser, editUser } from "@/api/UsersApi";
import { joinSplitPhoneNumber, splitPhoneNumber } from "@/utils/phoneUtils";

import { getSitesForFilter } from "@/api/SitesApi";
import { getSitesFilterItem } from "@/utils/filtersUtils";
import useFormUtils from "@/uses/useFormUtils";
import useUserRoles from "@/uses/useUserRoles";
import { showError, showErrorOptional } from "@/utils/notificationUtils";

export default defineComponent({
  name: "UserForm",
  props: { editModel: { default: null, type: Object as () => UserProfile } },
  components: { DropdownFilter, LeavePreventPopup },
  setup(props) {
    const store = useStore();
    const useRoles = useUserRoles(store);

    const isEdit = computed(() => props.editModel);

    const phoneNumber = isEdit.value
      ? splitPhoneNumber(props.editModel?.phone_number || "")
      : {};
    const form = reactive({
      first_name: "",
      last_name: "",
      title: "",
      email: "",
      country_code: "",
      phone_number: "",
      site_id: useRoles.getSiteId(),
      is_site_contact_person: false,
      role_ids: [],
      ...props.editModel,
      ...phoneNumber,
    });

    const rules = reactive({
      first_name: [
        {
          required: true,
          message: FIRST_NAME_MESSAGE,
          trigger: "blur",
        },
        {
          pattern: NAME_REGEX,
          message: AZ_MESSAGE,
          trigger: "blur",
        },
      ],
      last_name: [
        {
          required: true,
          message: LAST_NAME_MESSAGE,
          trigger: "blur",
        },
        {
          pattern: NAME_REGEX,
          message: AZ_MESSAGE,
          trigger: "blur",
        },
      ],
      title: [
        {
          pattern: NAME_REGEX,
          message: AZ_MESSAGE,
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: EMAIL_REQUIRED_MESSAGE,
          trigger: "blur",
        },
        {
          pattern: EMAIL_REGEX,
          message: EMAIL_CORRECT_MESSAGE,
          trigger: "blur",
        },
        {
          trigger: "blur",
          asyncValidator: (rule: any, value: string) => {
            return new Promise((resolve, reject) => {
              resolve(true);
              // setTimeout(() => {
              //   if (value === "kelmyashkin@gmail.com") {
              //     reject(USER_WITH_SAME_EMAIL_MESSAGE);
              //   } else {
              //     resolve(true);
              //   }
              // }, 1000);
            });
          },
        },
      ],
      country_code: [
        {
          pattern: PHONE_CODE_REGEX,
          message: CORRECT_PHONE_CODE_MESSAGE,
          trigger: "blur",
        },
      ],
      phone_number: [
        {
          pattern: PHONE_NUMBER_REGEX,
          message: CORRECT_PHONE_NUMBER_MESSAGE,
          trigger: "blur",
        },
      ],
      site_id: [
        {
          type: "string",
          message: "Site should be selected for specified role",
          validator: (rule: any, value: string) => {
            const result = intersection(form.role_ids, [
              UserRole.SiteAdmin,
              UserRole.Clinician,
            ]).length
              ? !!value
              : true;

            return result;
          },
        },
      ],
      role_ids: [
        {
          type: "array",
          required: true,
          message: "Please select the role",
          trigger: "blur",
        },
      ],
    });

    // System Admin - available for system admin
    // Site Admin - available for system and site admin
    // Clinician - available for system and site admin
    // Analyst -  available for system admin
    const isSystemAdmin = store.getters.isSystemAdmin as boolean;
    const state = reactive({
      isSystemAdmin,
      isSubmitted: false,
      loading: false,
      sites: {
        items: [],
        values: isEdit.value ? form.site_id : useRoles.getSiteId(),
      } as FilterObject,
      roles: {
        items: [
          {
            options: userRolesList
              .map((r) => ({
                value: r,
                text: getUserRoleTitle(r),
              }))
              .filter(
                (o) =>
                  !(
                    !isSystemAdmin &&
                    [UserRole.SystemAdmin, UserRole.Analyst].includes(o.value)
                  )
              ),
          },
        ],
        values: form.role_ids[0],
      } as FilterObject,
    });

    const showContactPersonCheckbox = computed(
      () =>
        intersection([UserRole.SiteAdmin, UserRole.Clinician], form.role_ids)
          .length
    );

    watch(state.sites, (newValue: any, oldValue: any) => {
      if (form.site_id != newValue.values) {
        form.site_id = newValue.values;
      }
    });

    watch(state.roles, (newValue: any, oldValue: any) => {
      if (!isEqual(form.role_ids, newValue.values)) {
        form.role_ids = [newValue.values];
        if (!showContactPersonCheckbox.value) {
          state.sites.values = "";
        }
      }
    });

    const sitesList = ref<SiteItemForFilter[]>([]);
    onBeforeMount(() => {
      if (useRoles.hasRole(UserRole.SystemAdmin)) {
        getSitesForFilter().then((sites) => {
          sitesList.value = sites.entities;
          state.sites.items = getSitesFilterItem(sites);
        });
      }
    });

    const userForm = ref<any>(null);
    return {
      state,
      form,
      rules,
      isEdit,
      loading: false,
      ...useFormUtils(form),
      role: computed(
        () =>
          state.roles.values ||
          // .map((v: UserRole) => getUserRoleTitle(v)).join(", ")
          "-"
      ),
      showContactPersonCheckbox,
      site: computed(() => {
        const value = state.sites.values as string;

        const site = sitesList.value.find((s) => s.id === value);

        return site?.name || "-";
      }),
      userForm,
      isRequiredFilled: computed(
        () => !!(form.last_name && form.first_name && form.email)
      ),
      submitForm() {
        state.loading = true;
        userForm.value.validate(async (valid: boolean) => {
          try {
            if (valid) {
              const model = {
                ...form,
                phone_number: joinSplitPhoneNumber({
                  country_code: form.country_code,
                  phone_number: form.phone_number,
                }),
              };

              if (isEdit.value) {
                await editUser(model, props.editModel.site_id);
              } else {
                await createUser(model);
              }

              // state.loading = false;
              state.isSubmitted = true;

              setTimeout(() => router.back(), 300);
            } else {
              return false;
            }
          } catch (ex) {
            if (
              ex?.error?.response?.data?.title === "The user already exists."
            ) {
              showErrorOptional(
                "This email is already used by another user",
                ex,
                "Error while submitting"
              );
            } else {
              showErrorOptional(
                ex?.error?.response?.data?.title,
                ex,
                "Error while submitting"
              );
            }
          } finally {
            state.loading = false;
          }
        });
      },
      cancel() {
        router.back();
      },
    };
  },
});
