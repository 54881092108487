import { defineComponent } from "vue";

import UserForm from "./../UserForm/UserForm.vue";

export default defineComponent({
  name: "AddUserForm",
  props: {},
  components: { UserForm },
  setup() {
    return {};
  },
});
