export interface SplitPhoneNumber {
  country_code?: string;
  phone_number?: string;
}

export function splitPhoneNumber(
  phoneNumber: string | undefined
): SplitPhoneNumber {
  if (!phoneNumber) return {};

  const length = phoneNumber.length;

  const country_code = phoneNumber.substring(0, length - 10);
  const phone_number = phoneNumber.substring(length - 10 || 0);

  return { country_code, phone_number };
}

export function joinSplitPhoneNumber(phoneNumber: SplitPhoneNumber) {
  const code = phoneNumber?.country_code?.replace("+", "");
  return (code || "") + (phoneNumber.phone_number || "");
}
